<template>
    <div class="menu-page">
        <h1>Denúncias</h1>

        <div class="mt-6">
            <v-data-table :headers="headers" no-data-text="Sem denúncias por aqui" :items="denounces" :items-per-page="50" class="elevation-1" :footer-props="{
                'items-per-page-text': 'Linhas por página',
                'items-per-page-all-text': 'Tudo',
                'items-per-page-options': [5, 10, 30, 50, 100, -1],
            }">

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium class="mr-4" color="primary" @click.stop="new_denounce = item; modalDenounce = true" v-bind="attrs" v-on="on"> mdi-file-edit-outline</v-icon>
                            </template>
                            <span>Editar denúncia</span>
                        </v-tooltip>

                        <!--
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium class="mr-4" color="error" @click.stop="new_denounce = item; dialog = true" v-bind="attrs" v-on="on"> mdi-trash-can-outline</v-icon>
                            </template>
                            <span>Apagar denúncia</span>
                        </v-tooltip>
                        -->
                    </div>
                </template>

                <template v-slot:[`item.resolved`]="{ item }">
                    {{ item.resolved ? 'Sim' : 'Não' }}
                </template>
            </v-data-table>
        </div>

        <v-bottom-sheet v-model="modalDenounce">
            <v-sheet class="bottom-modal-container" height="auto" v-if="new_denounce && new_denounce.car">
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense disabled v-model="new_denounce.car.store.name" label="Nome da loja" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense disabled v-model="new_denounce.car.store.state" label="Estado da loja" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense disabled v-model="new_denounce.car.store.city" label="Cidade da loja" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense disabled v-model="new_denounce.car.store.phone" label="Telefone da loja" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense disabled v-model="new_denounce.user.name" label="Nome do usuário" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense disabled v-model="new_denounce.user.email" label="E-mail do usuário" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense disabled v-model="new_denounce.car.model" label="Carro denunciado" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-textarea autocomplete="nope" rows="5" dense disabled v-model="new_denounce.description" label="Descrição da denúncia" outlined hide-details="auto"></v-textarea>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-switch dense v-model="new_denounce.resolved" inset label="Resolvida?"></v-switch>
                    </v-col>

                    

                    <v-col cols="12">
                        <v-btn class="default-button" color="#123cd7" block @click.native="createOrUpdateDenounce" :loading="buttonLoading">Editar Denúncia</v-btn>
                    </v-col>

                </v-row>
            </v-sheet>
        </v-bottom-sheet>

        <!--
        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar loja
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a loja <b>{{ new_denounce.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; deleteStore()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    -->
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "DenouncesView",

    data() {
        return {
            denounces: [],
            modalDenounce: false,
            new_denounce: {},
            buttonLoading: false,
            dialog: false,
            headers: [
                { text: "Anúncio", value: "car.model", filterable: true },
                { text: "Denúncia", value: "description", filterable: true },
                { text: "Loja", value: "car.store.name", filterable: true },
                { text: "Usuário", value: "user.email", filterable: true },
                { text: "Resolvida?", value: "resolved" },
                { text: "Ações", value: "actions" },
            ]
        };
    },


    async mounted() {
        await this.getDenounces();
    },

    methods: {

        async getDenounces() {
            const resp = await Api.getDenounces();
            if (resp && resp.message) this.denounces = resp.message;
        },

        async createOrUpdateDenounce() {

            this.buttonLoading = true;

            const resp = await Api.createOrUpdateDenounce(this.new_denounce);
            if (resp && resp.message) {
                this.limpaFormulario();
                this.modalDenounce = false;
                this.buttonLoading = false;
                this.getDenounces();
                return emitToastr('success', 'Denúncia editada com sucesso.');
            }
        },

        limpaFormulario() {
            this.new_denounce = {};
        },

        /*
        async deleteStore() {
            this.new_denounce.filed = true;

            const resp = await Api.createOrUpdateDenounce(this.new_denounce);
            if (resp && !resp.error) {
                this.limpaFormulario();
                this.modalDenounce = false;
                this.buttonLoading = false;
                this.getDenounces();
                return emitToastr('success', 'Denúncia apagada com sucesso.');
            }
        }
        */
    },
};
</script>
  
<style scoped>
.find-bar {
    display: flex;
    flex-direction: row;
    max-width: 400px;

}

.new-store-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.actions-container {
    display: flex;
    flex-direction: row;

}

.bottom-modal-container {
    padding: 4%;
}

@media only screen and (max-width: 768px) {}
</style>
  