<template>
    <div class="menu-page">
        <h1>Usuários</h1>

        <div class="find-bar mb-4">
            <v-text-field autocomplete="nope" dense v-model="filter" label="Busca por email" outlined hide-details="auto"></v-text-field>
            <v-btn class="ml-2 default-button" color="#123cd7" @click.native="getUsers({ filter: { email: { $regex: filter, $options: 'i' } } })">Buscar</v-btn>
        </div>

        <div class="new-store-button mb-4">
            <v-btn class="default-button" color="#123cd7" @click.native="new_user = {}; modalNewStore = true">Novo usuário</v-btn>
        </div>

        <div>
            <v-data-table :headers="headers" :items="users" :items-per-page="50" class="elevation-1" :footer-props="{
                'items-per-page-text': 'Linhas por página',
                'items-per-page-all-text': 'Tudo',
                'items-per-page-options': [5, 10, 30, 50, 100, -1],
            }">

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium class="mr-4" color="primary" @click.stop="new_user = item; modalNewStore = true" v-bind="attrs" v-on="on"> mdi-account-edit</v-icon>
                            </template>
                            <span>Editar usuário</span>
                        </v-tooltip>
                    </div>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    {{ getStatusName(item.status) }}
                </template>

                <template v-slot:[`item.created`]="{ item }">
                    {{ new Date(item.created).toLocaleString() }}
                </template>
            </v-data-table>
        </div>

        <v-bottom-sheet v-model="modalNewStore">
            <v-sheet class="bottom-modal-container" height="auto">
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_user.name" label="Nome" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_user.email" label="E-mail" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_user.password" label="Senha" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-select v-model="new_user.status" outlined dense :items="states" item-text="name" item-value="id" attach label="Status"></v-select>
                    </v-col>

                    <v-col cols="12">
                        <v-btn class="default-button" color="#123cd7" block @click.native="createOrUpdateUser" :loading="buttonLoading">Salvar usuário</v-btn>
                    </v-col>

                </v-row>
            </v-sheet>
        </v-bottom-sheet>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar loja
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o usuário <b>{{ new_user.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; disableUser()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "usersView",

    data() {
        return {
            users: [],
            modalNewStore: false,
            new_user: {},
            filter: "",
            noDataText: "Nenhuma opção",
            buttonLoading: false,
            dialog: false,
            headers: [
                { text: "Email", value: "email" },
                { text: "Nome", value: "name" },
                { text: "Status", value: "status" },
                { text: "Criação", value: "created" },
                { text: "Ações", value: "actions" },
            ],
            states: [
                { id: 'active', name: "Ativo" },
                { id: 'pastdue', name: "Em Atraso" },
                { id: 'canceled', name: "Cancelado" },
            ]
        };
    },


    async mounted() {
        await this.getUsers({ limit: 200 });
    },

    methods: {

        async getUsers(data) {
            const resp = await Api.getUsers(data);
            if (resp && resp.message && !resp.message.error) this.users = resp.message;
        },

        getStatusName(status) {
            if (status == 'active') return 'Ativo';
            if (status == 'pastdue') return 'Em Atraso';
            if (status == 'canceled') return 'Cancelado';
        },

        async createOrUpdateUser() {
            if (!this.new_user.name) return emitToastr('error', 'Nome obrigatório.');
            if (!this.new_user.email) return emitToastr('error', 'E-mail obrigatório.');
            if (!this.new_user.status) return emitToastr('error', 'Status obrigatório.');

            this.buttonLoading = true;

            const resp = await Api.createOrUpdateUser(this.new_user);
            if (resp && resp.message) {
                this.limpaFormulario();
                this.modalNewStore = false;
                this.buttonLoading = false;
                this.getUsers();
                return emitToastr('success', 'Usuário cadastrado com sucesso.');
            }
        },

        limpaFormulario() {
            this.new_user = {};
        },

        async disableUser() {
            this.new_user.status = 'canceled';

            const resp = await Api.createOrUpdateUser(this.new_user);
            if (resp && !resp.error) {
                this.limpaFormulario();
                this.modalNewStore = false;
                this.buttonLoading = false;
                this.getUsers();
                return emitToastr('success', 'Usuário desativado com sucesso.');
            }
        }
    },
};
</script>
  
<style scoped>
.find-bar {
    display: flex;
    flex-direction: row;
    max-width: 400px;
}

.new-store-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.actions-container {
    display: flex;
    flex-direction: row;

}

.bottom-modal-container {
    padding: 4%;
}

@media only screen and (max-width: 768px) {}
</style>
  