<template>
    <div class="menu-page">
        <h1>Auditoria</h1>

        <div class="mt-6">
            <v-text-field v-model="search" append-icon="mdi-magnify" class="mb-4" label="Pesquisar por e-mail" single-line clearable autocomplete="nope" dense outlined hide-details="auto" style="max-width:500px"></v-text-field>

            <v-data-table :headers="headers" no-data-text="Sem dados de auditoria" :items="audit_data" :search="search" :items-per-page="50" class="elevation-1" :footer-props="{
                'items-per-page-text': 'Linhas por página',
                'items-per-page-all-text': 'Tudo',
                'items-per-page-options': [5, 10, 30, 50, 100, -1],
            }">

                <template v-slot:no-results>
                    <span>Sem resultados para essa busca</span>
                </template>

                <template v-slot:[`item.created`]="{ item }">
                    {{ new Date(item.created).toLocaleString() }}
                </template>

                <template v-slot:[`item.new_data`]="{ item }">
                    <div v-if="item.old_data"> {{ `${item.old_data.status} --> ${item.new_data.status}` }}</div>
                    <div v-if="!item.old_data"> {{ `${item.new_data.status} (Novo usuário)` }}</div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "AuditView",

    data() {
        return {
            audit_data: [],
            search: '',
            buttonLoading: false,
            headers: [
                { text: "Data", value: "created", filterable: true },
                { text: "Admin", value: "admin_user.email", filterable: true },
                { text: "Cliente", value: "customer_user.email", filterable: true },
                { text: "Status", value: "new_data", filterable: true }
            ]
        };
    },


    async mounted() {
        await this.getAuditData();
    },

    methods: {

        async getAuditData() {
            const resp = await Api.getAuditData();
            if (resp && resp.message) this.audit_data = resp.message;
        }
    },
};
</script>
  
<style scoped>
.find-bar {
    display: flex;
    flex-direction: row;
    max-width: 400px;
}

@media only screen and (max-width: 768px) {}
</style>
  