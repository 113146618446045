const toastr = require("toastr");
const toastrOptions = { transitionIn: "fadeIn", transitionOut: "fadeOut", position: "top-right", progressBar: true };

const emitToastr = (type = "error", title = "", message = "") => {
  return toastr[type](title, message, toastrOptions);
};

const getSobre = () => {
  return `<h1>Clube do Carro: Uma Plataforma de Marketplace para Compras de Veículos</h1>
  <br>

  <p>Bem-vindo ao Clube do Carro! Gostaríamos de esclarecer que somos uma plataforma de marketplace projetada para facilitar a compra e venda de veículos entre clientes e lojistas parceiros. É importante ressaltar que atuamos como intermediários nesse processo, proporcionando uma plataforma segura e confiável para conectar compradores e vendedores.</p>
  
  <p>No Clube do Carro, nossa missão é proporcionar aos nossos assinantes a oportunidade de adquirir veículos abaixo da tabela FIPE, oferecendo uma ampla variedade de opções e preços competitivos. No entanto, é fundamental compreender que não somos os responsáveis diretos pela negociação entre o cliente e o lojista responsável pelo carro em questão.</p>
  
  <p>Nossa plataforma funciona como um ambiente virtual no qual os lojistas parceiros cadastram os veículos disponíveis para venda, fornecendo informações detalhadas, fotografias e preços atrativos. Os clientes têm a possibilidade de explorar as opções, entrar em contato com os vendedores e realizar suas negociações.</p>
  
  <p>É importante ressaltar que não temos controle direto sobre as condições dos veículos anunciados, bem como não podemos garantir a veracidade das informações fornecidas pelos lojistas. Recomendamos enfaticamente que nossos assinantes sigam as dicas de segurança fornecidas anteriormente ao considerar a compra de um veículo.</p>
  
  <p>A responsabilidade pelas negociações, inspeções mecânicas, verificações de documentação e demais aspectos relacionados à compra do veículo recai inteiramente sobre o cliente e o lojista envolvido na transação. Encorajamos nossos assinantes a realizar uma pesquisa detalhada, verificar a procedência dos veículos, fazer test drives e, se possível, contar com o auxílio de um mecânico de confiança antes de finalizar a compra.</p>
  
  
  <p>Embora façamos o possível para garantir a segurança e a qualidade dos anúncios em nossa plataforma, não podemos nos responsabilizar por eventuais problemas, desacordos ou inconveniências decorrentes das negociações entre compradores e vendedores.</p>
  
  <p>Estamos sempre empenhados em aprimorar nossos serviços e garantir a melhor experiência possível aos nossos assinantes. Portanto, se surgirem problemas relacionados a um anúncio específico ou a qualquer aspecto da plataforma, entre em contato conosco imediatamente para que possamos investigar e tomar as medidas necessárias.</p>

  <p>No Clube do Carro, buscamos oferecer um ambiente seguro e confiável para a compra de veículos usados, conectando compradores e vendedores de forma transparente. Nossa prioridade é fornecer uma plataforma de qualidade, permitindo que nossos assinantes realizem negociações vantajosas e satisfatórias.</p>
  
  <p>Agradecemos por escolher o Clube do Carro e esperamos que essa plataforma atenda às suas expectativas. Estamos aqui para ajudá-lo em qualquer dúvida ou necessidade relacionada à nossa plataforma.</p>
  `;
};

const getDicas = () => {
  return `<h1>Dicas de segurança</h1>
  <br>

  
  <h3>1. Verifique a procedência do veículo:</h3>
  
  Solicite o histórico completo do veículo, incluindo registro de propriedade, histórico de acidentes, registro de serviços e manutenção.
  Verifique se o número de chassi e placa correspondem aos registros do veículo.
  Realize uma pesquisa online para verificar se o veículo não está com alguma restrição.
  
  <h3>2. Faça um test drive:</h3>
  
  
  Sempre teste o veículo antes de finalizar a compra.
  
  <h3>3. Inspeção mecânica e estrutural:</h3>
  
  
  Considere contratar um mecânico de confiança ou uma empresa de vistoria cautelar para fazer uma inspeção completa do veículo.
  Verifique o estado dos pneus, suspensão, freios, sistema de escapamento e outros componentes essenciais.
  Verifique se há vazamentos de óleo ou outros fluidos.
  
  <h3>4. Verifique a documentação:</h3>
  
  
  Certifique-se de que todos os documentos do veículo estejam em ordem, como o Certificado de Registro do Veículo (CRV) e o Certificado de Registro e Licenciamento do Veículo (CRLV).
  Verifique se não há pendências financeiras ou multas relacionadas ao veículo.
  
  <h3>5. Negociação segura:</h3>
  
  
  Realize a negociação sempre no endereço fisico da concessionária.
  Evite levar grandes quantias em dinheiro. Opte por realizar o pagamento por meio de transferência bancária, garantindo um registro da transação.
  Paganeto sempre na conta CNPJ da concessionária.
  
  <h3>6. Contrato de compra e venda:</h3>
  
  
  Sempre formalize a transação por meio de um contrato de compra e venda.
  O contrato deve conter informações completas sobre o veículo, valor acordado, condições de pagamento, prazos e eventuais cláusulas de garantia.
  
  <h3>7. Verifique a autenticidade dos documentos:</h3>
  
  
  Certifique-se de que todos os documentos apresentados sejam originais e não falsificados.
  Verifique se há sinais de adulteração, como rasuras, erros de impressão ou assinaturas suspeitas.
  
  O Clube do Carro é um Marketplace que conecta você aos nossos lojistas parceiros, portanto, se exime de qualquer responsábilidade na negociação. 
  Nosso trabalho é conectar você aos carros mais baratos do Brasil em alguns cliques! :D`;
};

const getTerms = () => {
  return `<div class="elementor-widget-container">
  <h1>
  Termos de uso
  </h1>
  <br>
  <h6 style="text-align: justify; line-height: 150%">
    <b><span style="">&nbsp;</span></b>
  </h6>
  <h6 style="text-align: justify; line-height: 150%">
    <b
      ><span style="">Olá, Bem-Vindo(a) ao Clube do Carro!<br /></span></b
    ><span style=""
      >Esses são os nossos Termos e Condições Gerais, um contrato entre você (usuário) e o <b>Clube do Carro</b>. Lembre-se que para se cadastrar na nossa plataforma, você deverá ler e concordar com os nossos Termos e, assim, poderá utilizar a plataforma. Para que possa usufruir dos nossos
      serviços é mandatório que você concorde com nossos Termos.<br /></span
    ><span style=""
      >Nos preocupamos muito com a transparência e a simplicidade, assim elaboramos esses Termos para que você possa entender de forma clara, ou seja, pensamos em um contrato direto, simples e sem letras miúdas ou asteriscos. Por isso, solicitamos que você leia atentamente este documento, pois
      disponibilizamos informações importantes sobre o uso da Plataforma <b>Clube do Carro</b>. Para começar, achamos importante você entender quais são os seus principais direitos e deveres e os nossos principais deveres.<br /></span
    ><b
      ><span style="">SEUS PRINCIPAIS DEVERES<br /></span></b
    ><span style="font-family: Wingdings; mso-fareast-font-family: Wingdings; mso-bidi-font-family: Wingdings"
      >v<span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 7pt;
          line-height: normal;
          font-family: 'Times New Roman';
        "
        >&nbsp;
      </span></span
    ><!--[endif]--><span style=""
      >Você não deve compartilhar seu login e senha com terceiros, nem autorizar que outra pessoa utilize a sua conta, caso você permita ou compartilhe, se responsabilizará pelas consequências do uso de login e senha de sua titularidade.<br /></span
    ><span style="font-family: Wingdings; mso-fareast-font-family: Wingdings; mso-bidi-font-family: Wingdings"
      >v<span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 7pt;
          line-height: normal;
          font-family: 'Times New Roman';
        "
        >&nbsp;
      </span></span
    ><!--[endif]--><span style="">É importante que o você tenha seriedade e verdade nos dados fornecidos na hora do cadastro. Neste sentido, esperamos que não utilize nenhum dado de terceiros para criar o cadastro.<br /></span
    ><span style="font-family: Wingdings; mso-fareast-font-family: Wingdings; mso-bidi-font-family: Wingdings"
      >v<span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 7pt;
          line-height: normal;
          font-family: 'Times New Roman';
        "
        >&nbsp;
      </span></span
    ><!--[endif]--><span style="">Você deve se respeitar as regras de convivência e de uso da plataforma, levando em consideração os princípios de boa-fé e convivência harmónica.<br /></span
    ><b
      ><span style="">PRINCIPAIS DEVERES DO DF DIGITAL.<br /></span></b
    ><span style="font-family: Wingdings; mso-fareast-font-family: Wingdings; mso-bidi-font-family: Wingdings"
      >v<span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 7pt;
          line-height: normal;
          font-family: 'Times New Roman';
        "
        >&nbsp;
      </span></span
    ><!--[endif]--><span style="">Disponibilizar a plataforma para que você, devidamente cadastrado(a), possa utilizar o nosso serviço.<br /></span
    ><span style="font-family: Wingdings; mso-fareast-font-family: Wingdings; mso-bidi-font-family: Wingdings"
      >v<span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 7pt;
          line-height: normal;
          font-family: 'Times New Roman';
        "
        >&nbsp;
      </span></span
    ><!--[endif]--><span style="">Proteger a confidencialidade e armazenamento dos dados fornecidos por você ao se cadastrar em nossa plataforma, estando em conformidade com a Lei Geral de Proteção de Dados (13.709/2018).<br /></span
    ><b
      ><span style="">1. QUEM SOMOS?<br /></span></b
    ><b><span style="">1.1.</span></b
    ><span style="">
      O Clube do Carro é uma plataforma destinada à facilitação de negociações entre usuários interessados em veículos. O Clube não participa das transações comerciais, não atua como concessionária e/ou proprietária de veículos e não garante a disponibilidade dos veículos anunciados. A
      responsabilidade pelas negociações e transações é exclusivamente dos usuários.<br /></span
    ><b><span style="">1.2.</span></b
    ><span style=""> Para mais informações sobre a Plataforma, quem somos, acesse o site www.clubedorepassebr.com<br /></span><b><span style="">1.3.</span></b
    ><span style="">
      Nossos Serviços são fornecidos pela <b>DF PRODUTOS DIGITAIS LTDA,</b> pessoa jurídica de direito privado, inscrita no CNPJ sob o nº. 46.764.674/0001-63, com sede na cidade de São José, Estado de Santa Catarina, na Rua Irmão Vieira, nº. 967, Bairro Campinas, CEP 88.101-290.<br /></span
    ><b
      ><span style="">2. DEFINIÇÕES.<br /></span></b
    ><span style="">Para os efeitos deste documento, os vocábulos e expressões abaixo têm as seguintes definições, podendo ser utilizados no singular ou plural:<br /></span><b><span style="">2.1.</span></b
    ><span style=""> “<b>USUÁRIO (S)”</b>: é a pessoa física ou jurídica que contrata o Serviço oferecida pelo<b> Clube do Carro</b>.<br /></span><b><span style="">2.2. </span></b
    ><span style=""
      >“<b>SERVIÇO”:</b> É a disponibilização, pelo <b>Clube do Carro</b>, de um conjunto de ferramentas e serviços online destinados ao setor de veículos, que busca otimizar a gestão do usuário por meio das funcionalidades citadas no item <b>5.1</b>.<br /></span
    ><b
      ><span style="">3. CONDIÇÕES GERAIS PARA A UTILIZAÇÃO DOS SERVIÇOS OFERECIDOS<br /></span></b
    ><b><span style="">3.1.</span></b
    ><span style=""> Para que o usuário possa utilizar a Plataforma<b> Clube do Carro</b>, bem como realizar o cadastro é obrigatória a leitura, compreensão e “Aceite” do presente Termo.<br /></span
    ><b><span style="">3.2.</span></b
    ><span style="">
      Ao concordar com o “Aceite”, seja por meio de dispositivo móvel ou computador, você declara expressamente que leu, entendeu e compreendeu todos os direitos e obrigações determinados no&nbsp; Termo, bem como os demais documentos e políticas a que o Termo se refere, em especial a Política de
      Privacidade. Ademais, você ainda declara que concorda com a coleta, transferência, armazenamento, guarda, tratamento e uso de seus Dados Pessoais, para os fins da prestação dos serviços ora contratados.<br /></span
    ><b><span style="">3.3</span></b
    ><span style=""
      >. O presente Termo poderá sofrer alterações periódicas, seja por questões legais ou de preferência do <b>Clube do Carro</b>. Mas sempre que isso ocorrer, iremos comunicá-lo, por e-mail ou outra forma, para que decida se quer seguir conosco.<br /></span
    ><b><span style="">3.4.</span></b
    ><span style="">
      Ao utilizar nosso sistema você poderá realizar um Cadastro, por meio do qual fornecerá alguns Dados Pessoais. Trataremos esses dados da forma mais transparente e segura, conforme explicamos melhor na nossa Política de Privacidade. Deixamos claro que toda e qualquer informação fornecida durante
      o cadastro será única e exclusivamente de sua responsabilidade.<br /></span
    ><b><span style="">3.5.</span></b
    ><span style="">
      O <b>Clube do Carro </b>não revisa as informações fornecidas por você. Contudo, nos reservamos o direito de verificar, a qualquer momento, a veracidade de tais informações e solicitar, a nosso exclusivo critério, esclarecimentos e documentação suporte que julgarmos necessária para a devida
      comprovação das informações prestadas.<br /></span
    ><b><span style="">3.6.</span></b
    ><span style=""> Ao criar uma conta e utilizar os Serviços ora ofertados, o <b>USUÁRIO</b> declara e garante que:<br /></span
    ><b
      ><span style="; mso-fareast-font-family: 'Arial Narrow'; mso-bidi-font-family: 'Arial Narrow'"
        >1.<span
          style="
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            font-variant-alternates: normal;
            font-kerning: auto;
            font-optical-sizing: auto;
            font-feature-settings: normal;
            font-variation-settings: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 7pt;
            line-height: normal;
            font-family: 'Times New Roman';
          "
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ></b
    ><!--[endif]--><span style="">Os dados informados na Plataforma são verdadeiros e corretos;<br /></span
    ><b
      ><span style="; mso-fareast-font-family: 'Arial Narrow'; mso-bidi-font-family: 'Arial Narrow'"
        >2.<span
          style="
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            font-variant-alternates: normal;
            font-kerning: auto;
            font-optical-sizing: auto;
            font-feature-settings: normal;
            font-variation-settings: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 7pt;
            line-height: normal;
            font-family: 'Times New Roman';
          "
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ></b
    ><!--[endif]--><span style="">Não está impedido de utilizar os Serviços nos termos das leis da República Federativa do Brasil ou outra jurisdição aplicável;<br /></span
    ><b
      ><span style="; mso-fareast-font-family: 'Arial Narrow'; mso-bidi-font-family: 'Arial Narrow'"
        >3.<span
          style="
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            font-variant-alternates: normal;
            font-kerning: auto;
            font-optical-sizing: auto;
            font-feature-settings: normal;
            font-variation-settings: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 7pt;
            line-height: normal;
            font-family: 'Times New Roman';
          "
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ></b
    ><!--[endif]--><span style="">Cumprirá este Termo de Uso e todas as leis, normas e regulamentos municipais, estaduais, nacionais e internacionais aplicáveis;<br /></span
    ><b
      ><span style="; mso-fareast-font-family: 'Arial Narrow'; mso-bidi-font-family: 'Arial Narrow'"
        >4.<span
          style="
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            font-variant-alternates: normal;
            font-kerning: auto;
            font-optical-sizing: auto;
            font-feature-settings: normal;
            font-variation-settings: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 7pt;
            line-height: normal;
            font-family: 'Times New Roman';
          "
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span
      ></b
    ><!--[endif]--><span style="">Não irá forçar ou motivar outros usuários a descumprir este Termo de Uso.<br /></span><b><span style="">3.7.</span></b
    ><span style="">
      O <b>Clube do Carro </b>se reserva o direito de cancelar, suspender ou recusar o cadastro de qualquer Usuário na hipótese da existência de condenação, já transitada em julgado, por crimes hediondos ou hediondos por equiparação, por participação em associação ou em organização criminosa, por
      qualquer envolvimento com tráfico de drogas ou de pessoas, ou ainda em crimes cibernéticos ou outros quaisquer cometidos no ambiente virtual, por má reputação que manche a honra do <b>Clube do Carro </b>perante o público geral.<br /></span
    ><b><span style="">3.8.</span></b
    ><span style="">
      Na ocorrência de cancelamento, suspensão ou encerramento do cadastro de Usuário no sistema, o registro de nova conta ou o uso de conta de terceiro lhe é vedado, salvo na hipótese de não mais existirem os motivos que causaram a exclusão do cadastro da plataforma.<br /></span
    ><b><span style="">3.9.</span></b
    ><span style="">
      O Usuário é o único responsável pelos dados de acesso e por todas as atividades desenvolvidas na conta. Por conseguinte, o <a name="_Hlk135061243"></a><b>Clube do Carro </b>não pode ser responsabilizada por danos de qualquer natureza advindos do uso da conta pelo Usuário ou por terceiros que
      venham a utilizá-la. É o Usuário responsável por manter o login e a senha de sua conta em sigilo. Como esses dados são pessoais e intransferíveis, o Usuário não pode compartilhá-los com terceiros. Além disso, nenhum representante ou preposto do <b>Clube do Carro </b>possui autorização para
      exigir a senha ou o login de qualquer Usuário.<br /></span
    ><b
      ><span style="">4. QUEM PODE UTILIZAR NOSSOS SERVIÇOS?<br /></span></b
    ><b><span style="">4.1.</span></b
    ><span style=""> Todas as pessoas físicas capazes maiores e jurídicas podem utilizar dos nossos Serviços.<br /></span><b><span style="">4.2.</span></b
    ><span style=""> Os Serviços do <b>Clube do Carro </b>não poderão ser utilizados se:<br /></span><b><span style="">4.2.1.</span></b
    ><span style=""> Você for menor de 18 anos (ou estiver abaixo da idade legal mínima em seu país para usar a Plataforma), caso o seu representante legal não aceite este instrumento, bem como nossa Política de Privacidade;<br /></span
    ><b><span style="">&nbsp;4.2.2.</span></b
    ><span style=""> Nós tivermos desativado anteriormente sua conta por violações de nossos termos ou políticas;<br /></span><b><span style="">4.2.3.</span></b
    ><span style=""> Você estiver proibido de receber nossos produtos, serviços ou software de acordo com as leis aplicáveis;<br /></span><b><span style="">4.2.4.</span></b
    ><span style=""> Você utilizar os Serviços em desacordo com este Termo e demais Políticas do <b>Clube do Carro</b>.<br /></span
    ><b
      ><span style="">5. FUNCIONALIDADES DOS SERVIÇOS OFERECIDOS PELO Clube do Carro.<br /></span></b
    ><b><span style="">5.1.</span></b
    ><span style=""> Os serviços do sistema <b>Clube do Carro,</b> estão descritos nessa cláusula e compreendem o que segue abaixo, além dos itens seguintes.<br /></span
    ><b
      ><span style="">5.1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Plataforma de Negociação</u></span></b
    ><span style=""
      >: O Clube do Carro fornece uma plataforma online para que os usuários possam realizar negociações de veículos. Os usuários têm a responsabilidade de negociar diretamente entre si, sem a participação direta do Clube do Carro na transação.<br /></span
    ><b
      ><span style="">5.1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Ferramentas de Negociação</u></span></b
    ><span style=""
      >: O Clube do Carro disponibiliza as ferramentas necessárias para que os usuários realizem suas negociações<b>.<br /></b></span
    ><b
      ><span style="">5.1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>Publicação e Repasse de Anúncios</u></span></b
    ><span style=""
      >: O Clube do Carro atua como uma plataforma para a republicação e repasse de anúncios de veículos. No entanto, a responsabilidade sobre o conteúdo dos anúncios, bem como os produtos e serviços oferecidos, recai sobre os usuários.<br /><b> </b></span
    ><b><span style="">5.1.4</span></b
    ><span style=""
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><u>Serviços de Assinatura:</u></b> O Clube do Carro oferece serviços de assinatura para os usuários, com cobrança mensal de acordo com a forma de pagamento feita.<br /><b></b></span
    ><b
      ><span style="">6. REGRAS INTERNAS E ATITUDES REPUTIADAS<br /></span></b
    ><b><span style="">6.1. </span></b
    ><span style=""
      >A fim de garantir uma experiência de usuário segura e satisfatória na plataforma <b>Clube do Carro</b>, estabelecemos regras internas que devem ser seguidas por todos os usuários, sejam eles compradores ou vendedores. Ao utilizar a plataforma, os usuários concordam em cumprir integralmente
      estas regras, que foram elaboradas para proteger os interesses de todas as partes envolvidas e garantir a conformidade com a legislação aplicável, incluindo a Lei Geral de Proteção de Dados (LGPD).<br /></span
    ><b><span style="">6.2. </span></b
    ><span style=""
      >Os usuários devem conduzir suas negociações de maneira diligente, prudente e em conformidade com todas as leis aplicáveis. O <b>Clube do Carro</b> não se responsabiliza por eventuais falhas, problemas ou insucessos nas transações entre usuários.<br /></span
    ><b><span style="">6.3.</span></b
    ><span style="">
      Ao utilizar a plataforma <b>Clube do Carro</b>, os usuários concordam em seguir estas regras internas e reconhecem que o não cumprimento das mesmas pode resultar em penalidades, incluindo a suspensão ou encerramento da conta e medidas legais aplicáveis.<br /></span
    ><b><span style="">6.5.</span></b
    ><span style=""> É importante salientar que atitudes fraudulentas ou ilegais podem resultar em ações legais, como processos por falsificação ideológica ou outras consequências previstas em lei.<br /></span
    ><span style=""><br /></span><span style=""><br /></span><span style=""><br /></span
    ><b
      ><span style="">7. DA ASSINATURA E DOS PREÇOS<br /></span></b
    ><b><span style="">7.1.</span></b
    ><span style="">
      <b><u>Assinatura</u></b
      >: Ao se tornar membro do Clube do Carro, você concorda com a renovação automática da sua assinatura até que seja cancelada. A cobrança será realizada mensalmente, de acordo com a forma de pagamento registrada em sua conta.<br /></span
    ><b><span style="">7.2.</span></b
    ><span style="">
      <b><u>Preços</u></b
      >: O preço da assinatura do Clube do Carro é de R$99,90 no primeiro mês. Após o primeiro mês, a cobrança será de R$189,90 mensais. O Clube do Carro se reserva o direito de alterar os preços a qualquer momento, com prévio aviso.<br /></span
    ><b
      ><span style="">7.3. <u>Planos</u>:</span></b
    ><span style="">
      O Clube do Carro pode oferecer diversos planos de assinatura, incluindo planos com limitações de prazo ou participantes. Cada plano de assinatura pode ter condições e limitações específicas que serão informadas no momento da contratação.<br /></span
    ><b
      ><u><span style="">7.5. Alterações nos Planos e Preços</span></u></b
    ><span style="">: O Clube do Carro reserva-se o direito de modificar, terminar ou de outra forma alterar os planos de assinatura e preços a seu critério.<br /></span><b><span style="">7.2. </span></b
    ><span style="">Ao utilizar a plataforma <b>Clube do Carro</b>, os usuários concordam com esta política de remuneração e reconhecem que ela pode ser atualizada periodicamente.<br /></span
    ><b
      ><span style="">8. OPÇÕES DE CANCELAMENTO:<br /></span></b
    ><b><span style="">8.1. </span></b
    ><span style="">O <b>USUÁRIO </b>tem a ciência que poderá se arrepender dentro do prazo de 7 (sete) dias a contar da data da compra, em conformidade com o artigo 49 do Código de Defesa do Consumidor.<br /></span
    ><b><span style="">8.2.</span></b
    ><span style="">
      Ao fim do prazo de arrependimento de 07 (sete) dias após a assinatura, caso o <b>USUÁRIO </b>deseje cancelá-la, este deverá fazer o pedido por meio do endereço eletrônico atendimento@clubedorepassebr.com, solicitando o pronto cancelamento da assinatura. Nesta hipótese, não haverá devolução de
      quaisquer valores pagos pelo <b>USUÁRIO CONSUMIDOR</b>.<br /></span
    ><b><span style="">8.2. </span></b
    ><span style=""
      >Para evitar a renovação automática da sua assinatura, você deve solicitar o cancelamento com 2 (dois) dias úteis de antecedência da próxima cobrança. Caso contrário, você autoriza o Clube do Carro a cobrar a taxa de assinatura do próximo ciclo de faturamento usando o seu método de pagamento
      válido e registrado.<br /></span
    ><b
      ><span style="">9. DAS MEDIDAS PREVENTIVAS E SANÇÕES<br /></span></b
    ><b><span style="">9.1. Medidas.</span></b
    ><span style=""> Qualquer conduta contrária ao previsto nestes Termos de Uso poderá ser punida:<br /></span><b><span style="">(a)</span></b
    ><span style=""> Com a remoção de determinados usuários;<br /></span><b><span style="">(b)</span></b
    ><span style=""> Com a suspensão imediata do acesso à conta dos usuários;<br /></span><b><span style="">(c)</span></b
    ><span style=""> Com o bloqueio da conta dos usuários na Plataforma.<br /></span
    ><b
      ><span style="">10. DA RESPONSABILIDADE DO Clube do Carro<br /></span></b
    ><b><span style="">10.1.</span></b
    ><span style=""> Sem prejuízo das demais obrigações decorrentes destes Termos, o <b>Clube do Carro </b>tem o dever de:<br /></span><b><span style="">a)</span></b
    ><span style=""> Prestar os serviços por meio de conexões seguras; e<br /></span><b><span style="">b)</span></b
    ><span style=""> seguir a legislação e regulamentos pertinentes à prestação dos serviços.<br /></span><b><span style="">10.2.</span></b
    ><span style=""> O <b>Clube do Carro</b> não se responsabiliza por falhas de sistema, causados por eventos de força maior ou caso fortuito, que atrasem ou impeçam a realização de transações em sua Plataforma.<br /></span
    ><b><span style="">10.3.</span></b
    ><span style="">
      O <b>Clube do Carro </b>não responderá, em nenhuma hipótese, ainda que em caráter solidário ou subsidiário por eventuais prejuízos sofridos pelos usuários e por terceiros quando a falha na prestação dos serviços contratados decorrer de:<br /></span
    ><b><span style="">a)</span></b
    ><span style="">
      Fatores que fujam ao cabível controle do <b>Clube do Carro</b>, incluindo, mas não se limitando a caso fortuito, força maior, falta de acesso à internet do <b>Clube do Carro</b>, problemas correlatos ou, ainda, com a empresas fornecedoras de energia elétrica;<br /></span
    ><b><span style="">b)</span></b
    ><span style=""> Quaisquer atos ou omissões dos usuários;<br /></span><b><span style="">c)</span></b
    ><span style=""> Falhas de comunicação dos equipamentos que os usuários possuem, e que impeça o acesso regular aos serviços prestados pelo <b>Clube do Carro</b>;<br /></span><b><span style="">d)</span></b
    ><span style=""> Paradas programadas.<br /></span><b><span style="">10.4.</span></b
    ><span style=""
      >&nbsp; O <a name="_Hlk135063935"></a><b>Clube do Carro</b> se esforça para proporcionar um ambiente propício e saudável para as negociações, mas não garante o êxito de qualquer operação realizada através da plataforma. O Clube do Carro não será responsável por qualquer dano ou prejuízo,
      direto ou indireto, de qualquer natureza, decorrente da utilização da plataforma.<br /></span
    ><b><span style="">10.6. </span></b
    ><span style=""
      >O usuário concorda em indenizar, defender e isentar o <b>Clube do Carro</b> de todas e quaisquer reclamações, responsabilidades e custos, incluindo honorários advocatícios, decorrentes de quaisquer violações destes Termos de Uso, ou de qualquer ato ou omissão do usuário que resulte em
      prejuízo ao Clube do Carro ou a terceiros.<br /></span
    ><b><span style="">10.7.</span></b
    ><span style="">
      Os usuários concordam em indenizar e isentar a plataforma <b>Clube do Carro</b>, seus diretores, funcionários e parceiros, de quaisquer reclamações, responsabilidades, danos, perdas ou despesas decorrentes de violações dos Termos de Uso, da legislação aplicável ou de direitos de
      terceiros.<br /></span
    ><b><span style="">10.8. </span></b
    ><span style=""
      >O usuário é responsável por cumprir todas as obrigações fiscais decorrentes das transações realizadas através da plataforma, de acordo com a legislação tributária aplicável. O <b>Clube do Carro</b> não assume responsabilidade pelo cumprimento das obrigações fiscais do usuário.<br /></span
    ><b><span style="">10.9.</span></b
    ><span style="">
      As informações e conselhos fornecidos pelo <b>Clube do Carro</b> não criam qualquer garantia ou responsabilidade que não tenha sido expressamente mencionada nestes Termos de Uso. O usuário deve confiar apenas nas disposições expressas nestes Termos de Uso para determinar seus direitos e
      obrigações.<br /></span
    ><b
      ><span style="">11. DA VIGÊNCIA<br /></span></b
    ><b><span style="">11.1.</span></b
    ><span style=""> O presente Termo vigorará por prazo indeterminado, contando-se a partir da data do Aceite.<br /></span><b><span style="">11.2.</span></b
    ><span style="">
      Seu cadastro permanecerá válido e ativo enquanto seu login estiver ativo. Você poderá desativá-lo, deixando de usar o sistema qualquer momento. A não utilização desta não resulta em cancelamento. Por meio de nossos canais de comunicação, você precisa realizar o pedido de cancelamento para que
      sua conta seja desativada e seus dados sejam apagados ou anonimizados.<br /></span
    ><b><span style="">11.3.</span></b
    ><span style="">
      O <b>Clube do Carro</b> poderá cancelar sua conta a qualquer momento, sem aviso prévio, se acreditar que você violou este Termo de Uso, sem que isto lhe dê direto a qualquer ressarcimento ou indenização. Após o cancelamento de sua conta, este Termo será rescindido, respondendo o
      <b>USUÁRIO</b> pelas perdas e danos que der causa.<br /></span
    ><b><span style="">11.4</span></b
    ><span style=""> O <b>Clube do Carro</b> também poderá deixar de licenciar a Plataforma ao <b>USUÁRIO</b> nas hipóteses de alteração dos Serviços ofertados ou o presente Termo ou acaso encerremos as nossas atividades.<br /></span
    ><b
      ><span style="">12. DA PROTEÇÃO DOS DADOS PESSOAIS<br /></span></b
    ><b><span style="">12.1</span></b
    ><span style=""> O <b>Clube do Carro</b>, está ciente que deverá cumprir a Lei n.º 13.709/18 – Lei Geral de Proteção de Dados Pessoais (LGPD e, em relação ao tratamento dados pessoais, obriga-se:<br /></span
    ><b><span style="">12.1.1</span></b
    ><span style=""> A tratar os Dados Pessoais em conformidade com a Legislação Brasileira, acatando o respeito aos princípios da finalidade, da adequação, da necessidade, da não discriminação e os direitos dos titulares de dados pessoais;<br /></span
    ><b><span style="">12.1.2</span></b
    ><span style=""> Atender aos chamados das Autoridades Fiscalizadoras e dos Titulares de Dados Pessoais;<br /></span><b><span style="">12.1.3</span></b
    ><span style=""> A se comprometer a manter a confidencialidade dos Dados Pessoais.<br /></span><b><span style="">12.2</span></b
    ><span style=""> O <b>Clube do Carro</b>, como operador se compromete a cumprir estritamente as diretrizes totais da Lei Geral de Proteção de Dados, devendo:<br /></span><b><span style="">12.2.1</span></b
    ><span style=""> Tratar os Dados Pessoais apenas para as finalidades objeto deste Termo de Uso;<br /></span><b><span style="">12.2.2</span></b
    ><span style=""> Promover a adoção de medidas técnicas, administrativas e organizacionais adequadas à natureza das suas atividades, como também à capacidade econômica adequada para proteção dos Dados Pessoais;<br /></span
    ><b><span style="">12.2.3</span></b
    ><span style=""> Garantir a confidencialidade dos Dados Pessoais, por si, por seus empregados e subcontratados;<br /></span
    ><b
      ><span style="">13. DAS DISPOSIÇÕES FINAIS<br /></span></b
    ><b><span style="">13.1 Confidencialidade.</span></b
    ><span style="">
      Nós e você temos o dever e obrigação de manter o sigilo e a não revelar a terceiros, em quaisquer circunstâncias informações decorrentes dos nossos Serviços. Assim, cada um de nós tratará de forma estritamente confidencial as informações levadas a seu conhecimento pela outra Parte e somente as
      utilizará no âmbito dos serviços ora pactuados.<br /></span
    ><b><span style="">13.2. Notificações.</span></b
    ><span style=""> Fica compactuado entre as partes que qualquer aviso, requerimento, comunicação ou interpelação relacionada a estes termos deverá ser efetuada por escrito ou qualquer meio eletrônico que permita a comunicação.<br /></span
    ><b><span style="">13.3. Validade de Disposições.</span></b
    ><span style="">
      A inexistência, invalidade ou ineficácia de quaisquer disposições destes Termos de Uso não prejudicará as demais. Caso qualquer disposição seja invalidada, no todo ou em parte, considerar-se-ão os termos como modificados com a exclusão ou a modificação, na extensão necessária da disposição
      inexistente, inválida ou ineficaz, de modo a manter a higidez destes Termos de Uso e, na medida do possível, preservar a intenção original das Partes.<br /></span
    ><b><span style="">13.4. Revisão dos Termos.</span></b
    ><span style=""> O <b>Clube do Carro</b> tem a liberdade de revisar e alterar estes Termos de Uso periodicamente. Caso sejam feitas alterações, o <b>Clube do Carro</b> notificará os usuários por e-mail.<br /></span
    ><b><span style="">13.5.1.</span></b
    ><span style="font-family: Arial Narrow, sans-serif"> Cada nova versão dos Termos de Uso entrará em vigor </span><a><span style="font-family: Arial Narrow, sans-serif">7 (sete) </span></a
    ><span style="">dias após a comunicação dos usuários via e-mail.<br /></span
    ><b
      ><span style="">14. LEI APLICÁVEL E CLÁUSULA DE ELEIÇÃO DE FORO<br /></span></b
    ><b><span style="">14.1. Lei Aplicável e Foro.</span></b
    ><span style="">
      Estes Termos de Uso são regidos pelas leis brasileiras e as partes elegem o Foro da Comarca de São José/SC para dirimir quaisquer dúvidas ou controvérsias oriundas destes Termos de Uso e Documentos Conexos, com a exclusão de qualquer outro, por mais privilegiado que seja.<br /></span
    ><span style="">•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Declaro que li, aceito e estou ciente das condições acima especificadas.</span>
  </h6>
  <div>
    <p><!-- [if !supportAnnotations]--></p>
    <p><!--[endif]--></p>
    <div>
      <p><!-- [if !supportAnnotations]--></p>
      <div id="_com_1" class="msocomtxt">
        <p>
          <!--[endif]--><!-- [if !supportAnnotations]--><a name="_msocom_1"></a
          ><!--[endif]-->
        </p>
        <p><!-- [if !supportAnnotations]--></p>
      </div>
      <p><!--[endif]--></p>
    </div>
  </div>
</div>
`;
};

export { emitToastr, getTerms, getDicas, getSobre };
