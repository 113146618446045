<template>
    <div class="menu-page">
        <h1>Meu Perfil</h1>

        <v-card class="change-password-card mt-8">
            <v-card-title>Mudar senha</v-card-title>
            <v-card-text>
                <v-col cols="12">
                    <v-text-field type="password" autocomplete="nope" dense v-model="old_password" label="Senha antiga" outlined hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-text-field type="password" autocomplete="nope" dense v-model="password" label="Nova senha" outlined hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-text-field type="password" autocomplete="nope" dense v-model="password_confirmation" label="Confirmação nova senha" outlined hide-details="auto"></v-text-field>
                </v-col>
            </v-card-text>

            <v-card-actions>
                <v-btn block class="default-button" color="#123cd7" @click.native="changePassword">Mudar senha</v-btn>
            </v-card-actions>
        </v-card>

        <v-card class="change-password-card mt-8">
            <v-card-title>Cancelar assinatura</v-card-title>

            <v-card-actions>
                <v-btn block class="default-button" color="red lighten-2" @click.native="dialog = true">Cancelar</v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Cancelamento de assinatura
                </v-card-title>
                <v-card-text>Olá, para confirmar o cancelamento é so concluir por esse site que estou enviando logo abaixo.</v-card-text>
                <v-card-text><a href="https://form.respondi.app/6EsGWKtz" target="_blank">https://form.respondi.app/6EsGWKtz</a></v-card-text>
                <v-card-text>Após o preenchimento do formulário, em até 02 dias úteis sua assinatura será cancelada e não haverá novas cobranças.</v-card-text>
                <v-card-text>
                    Atenciosamente, Suporte ClubeDoRepasseBR.
                </v-card-text>


                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#123cd7" @click="dialog = false">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from '@/lib/Api';
import { emitToastr } from "@/lib/Utils";

export default {
    name: "ProfileView",

    data() {
        return {
            password: '',
            password_confirmation: '',
            old_password: '',
            dialog: false
        };
    },

    components: {},

    methods: {
        async changePassword() {
            if (this.password.length < 4) return emitToastr('error', 'A senha precisa ter pelo menos 4 caracteres.')
            if (this.password != this.password_confirmation) return emitToastr('error', 'A senha não confere com a confirmação de senha.')
            if (!this.old_password) return emitToastr('error', 'É necessário digitar a senha antiga.')

            const resp = await Api.changeUserPassword(this.old_password, this.password);
            if (resp && resp.error === false) {
                emitToastr('success', 'Senha alterada com sucesso!');
            }
        }
    },
};
</script>
  
<style scoped>
.change-password-card {
    max-width: 400px;
}
</style>
  