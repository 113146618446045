<template>
    <div class="menu-page">
        <h1>Dashboard</h1>

        <h2 class="mt-4">Base atual</h2>
        <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-4 pb-4" color="success" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-account-multiple-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="real_time.active" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Ativos</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-4 pb-4" color="amber darken-1" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-alert-box-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="real_time.pastdue" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Em atraso</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-4 pb-4" color="red darken-1" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-close-circle-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">

                                    <animated-number :value="real_time.canceled" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Cancelados</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <h2 class="mt-12">Novos veículos</h2>
        <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-car </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="new_cars.today" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Hoje</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-car</v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="new_cars.week" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">7 dias</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-car </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="new_cars.month" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">30 dias</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <h2 class="mt-12">Novas vendas</h2>
        <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-account-plus-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_status.today" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">Hoje</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-account-plus-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_status.week" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">7 dias</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <v-icon class="icon" left> mdi-account-plus-outline </v-icon>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_status.month" round="1" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-1">30 dias</span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <h2 class="mt-12">Faturamento</h2>
        <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">Hoje</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.today.new_clients + sell_income.today.old_clients" round="1" :formatValue="formatToPrice" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.today.new_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Novos
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.today.old_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Recorrências
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">7 dias</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.week.new_clients + sell_income.week.old_clients" round="1" :formatValue="formatToPrice" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.week.new_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Novos
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.week.old_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Recorrências
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                <v-card class="mx-auto pt-1 pb-1" color="success" elevation="2" dark>
                    <v-card-title>
                        <div class="card-div">
                            <v-icon class="icon" left> mdi-currency-usd </v-icon>
                            <div class="text-subtitle-1">30 dias</div>
                        </div>
                        <v-spacer></v-spacer>
                        <div right class="div-text">
                            <v-row>
                                <span class="text-h3 font-weight-light">
                                    <animated-number :value="sell_income.month.new_clients + sell_income.month.old_clients" round="1" :formatValue="formatToPrice" :duration="600" />
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.month.new_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Novos
                                </span>
                            </v-row>
                            <v-row>
                                <span class="subtitle-2">
                                    <animated-number :value="sell_income.month.old_clients" round="1" :formatValue="formatToPrice" :duration="600" /> - Recorrências
                                </span>
                            </v-row>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>


        <h2 class="mt-12">Tipos de venda - 60 dias</h2>
        <v-row class="mt-2">
            <v-col cols="12">
                <div id="chart4">
                    <apexChart ref="chart4" height="450" type="line" :options="chart4Options" :series="series4"></apexChart>
                </div>
            </v-col>
        </v-row>

        <h2 class="mt-12">Distribuição horária de novas vendas - 7 dias</h2>
        <v-row class="mt-2">
            <v-col cols="12">
                <div id="chart2">
                    <apexChart ref="chart2" height="450" type="area" :options="chart2Options" :series="series2"></apexChart>
                </div>
            </v-col>
        </v-row>

        <h2 class="mt-12">Distribuição de ciclos - 30 dias</h2>
        <v-row class="mt-2">
            <v-col cols="12">
                <div id="chart3">
                    <apexChart ref="chart3" height="450" type="bar" :options="chart3Options" :series="series3"></apexChart>
                </div>
            </v-col>
        </v-row>

        <h2 class="mt-12">Faturamento diário - 60 dias</h2>
        <v-row class="mt-2">
            <v-col cols="12">
                <div id="chart4">
                    <apexChart ref="chart5" height="450" type="area" :options="chart5Options" :series="series5"></apexChart>
                </div>
            </v-col>
        </v-row>

        <h2 class="mt-12">Cancelamentos - 60 dias</h2>
        <v-row class="mt-2">
            <v-col cols="12">
                <div id="chart">
                    <apexChart ref="chart1" height="450" type="line" :options="chart1Options" :series="series1"></apexChart>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";
import AnimatedNumber from "animated-number-vue";

export default {
    name: "DashboardView",

    components: {
        AnimatedNumber
    },



    data() {
        return {
            real_time: { active: 0, pastdue: 0, canceled: 0 },
            sell_status: {
                today: 0,
                week: 0,
                month: 0,
            },
            sell_income: {
                today: { new_clients: 0, old_clients: 0, total: 0 },
                week: { new_clients: 0, old_clients: 0, total: 0 },
                month: { new_clients: 0, old_clients: 0, total: 0 },
            },
            new_cars: {
                today: 0,
                week: 0,
                month: 0,
            },

            series1: [],
            series2: [],
            series3: [],
            series4: [],
            series5: [],
            chart1Options: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true
                },
                colors: ['#e53935'],
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

            chart2Options: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true
                },
                colors: ['#4caf50'],
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },
            chart3Options: {
                chart: {
                    type: 'bar',
                    zoom: {
                        enabled: true
                    },
                },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

            chart4Options: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

            chart5Options: {
                chart: {
                    type: 'area',
                    zoom: {
                        enabled: true
                    }
                },
                colors: ['#4caf50'],
                dataLabels: {
                    enabled: true
                },
                legend: {
                    position: 'top',
                    fontSize: '16px',
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false
                }
            },

        };
    },

    async mounted() {
        const resp = await Api.getDashboardData();

        if (resp && resp.error) {
            return emitToastr("error", resp.message)
        }

        if (resp && resp.message) {
            this.real_time = resp.message.real_time;

            setTimeout(() => {
                this.sell_status = resp.message.sell_status;
                this.sell_income = resp.message.sell_income;
                this.new_cars = resp.message.new_cars;
            }, 500);

            if (resp.message.canceled_chart) {
                let cat = [];
                let canceled_series = { name: "Cancelados", data: [] };

                resp.message.canceled_chart.forEach(i => {
                    cat.push(i._id);
                    canceled_series.data.push(i.canceled);
                });

                this.series1 = [canceled_series];
                this.chart1Options.xaxis.categories = cat;
                this.$refs.chart1.refresh();
            }

            if (resp.message.sell_chart) {
                let cat = [];
                let active_series = { name: "Vendas", data: [] };

                resp.message.sell_chart.forEach(i => {
                    cat.push(i._id);
                    active_series.data.push(i.all);
                });

                this.series2 = [active_series];
                this.chart2Options.xaxis.categories = cat;
                this.$refs.chart2.refresh();
            }

            if (resp.message.cycle_chart) {
                let cat = [];
                let cycle_series = { name: "Ciclo", data: [] };

                resp.message.cycle_chart.forEach(i => {
                    cat.push(i._id);
                    cycle_series.data.push(i.all);
                });

                this.series3 = [cycle_series];
                this.chart3Options.xaxis.categories = cat;
                this.$refs.chart3.refresh();
            }

            if (resp.message.sell_type_chart) {
                let cat = [];
                let serie1 = { name: "Novas vendas", data: [] };
                let serie2 = { name: "Recorrências", data: [] };
                let serie3 = { name: "Total de vendas", data: [] };

                resp.message.sell_type_chart.forEach(i => {
                    cat.push(i._id);
                    serie1.data.push(i.new);
                    serie2.data.push(i.old);
                    serie3.data.push(i.new + i.old);
                });

                this.series4 = [serie1, serie2, serie3];
                this.chart4Options.xaxis.categories = cat;
                this.$refs.chart4.refresh();
            }

            if (resp.message.sell_income_chart) {
                let cat = [];
                let serie1 = { name: "Novas vendas", data: [] };

                resp.message.sell_income_chart.forEach(i => {
                    cat.push(i._id);
                    serie1.data.push(i.income);
                });

                this.series5 = [serie1];
                this.chart5Options.xaxis.categories = cat;
                this.$refs.chart5.refresh();
            }
        }

    },

    methods: {
        formatToPrice(value) {
            return `R$ ${value.toFixed(0)}`;
        }
    },
};
</script>
  
<style scoped>
.icon {
    font-size: 60px;
}

.mx-auto {}

.div-text {
    margin-right: 20px;
}

.card-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
  