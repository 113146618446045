import { render, staticRenderFns } from "./StoreRegisters.vue?vue&type=template&id=8991073a&scoped=true&"
import script from "./StoreRegisters.vue?vue&type=script&lang=js&"
export * from "./StoreRegisters.vue?vue&type=script&lang=js&"
import style0 from "./StoreRegisters.vue?vue&type=style&index=0&id=8991073a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8991073a",
  null
  
)

export default component.exports