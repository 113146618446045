<template>
    <div class="login_container">
        <v-img contain class="logo" src="@/assets/l_branco.png"></v-img>
        <v-card class="register-card">
            <v-card-title class="text-h5" style="word-break: break-word;">
                Conta de lojista
            </v-card-title>
            <v-card-text class="pb-0" v-if="!success">Cadastre e submeta sua loja para aprovação.</v-card-text>

            <v-card-text style="color: green; font-weight: bold" class="mb-4" v-if="success">Sucesso! Aguarde o retorno da nossa equipe.</v-card-text>

            <v-card-text class="pt-1" v-if="!success">
                <v-col cols="12">
                    <v-text-field dense v-model="new_store_account.store_name" label="Nome da loja" outlined hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field dense v-model="new_store_account.store_cnpj" v-mask="'##.###.###/####-##'" label="CNPJ" outlined hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field dense v-model="new_store_account.store_phone" v-mask="'(##) #########'" label="Telefone da loja com DDD" outlined hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete autocomplete="nope" no-data-text="Sem dados" dense :items="$root.$refs.global.states" v-model="new_store_account.store_state" item-text="name" item-value="id" label="Estado" outlined hide-details="auto"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-text-field dense v-model="new_store_account.store_city" label="Cidade" outlined hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>

                <v-card-subtitle class="pt-1 pb-1">Dados de acesso</v-card-subtitle>
                <v-col cols="12">
                    <v-text-field dense v-model="new_store_account.user_name" label="Nome e sobrenome" outlined hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field dense type="email" v-model="new_store_account.user_email" label="E-mail" outlined hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field dense type="password" v-model="new_store_account.user_password" label="Senha" outlined hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field dense type="password" v-model="new_store_account.password2" label="Confirmação de senha" outlined hide-details="auto"></v-text-field>
                </v-col>


            </v-card-text>

            <v-card-actions v-if="!success">
                <v-btn class="default-button mb-4" color="red lighten-2" text @click="dialogNewStore = false">
                    Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="default-button mb-4" color="#123cd7" @click="dialogNewStore = false; createStoreRegister()">
                    Cadastrar
                </v-btn>
            </v-card-actions>
        </v-card>

    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "LoginView",

    data() {
        return {
            new_store_account: {},
            success: false
        };
    },

    components: {},

    methods: {

        async createStoreRegister() {
            if (!this.new_store_account.store_name) return emitToastr("error", "Nome da loja é obrigatório");
            if (!this.new_store_account.store_cnpj) return emitToastr("error", "CNPJ da loja é obrigatório");
            if (!this.new_store_account.store_phone) return emitToastr("error", "Telefone da loja é obrigatório");
            if (!this.new_store_account.store_state) return emitToastr("error", "Estado da loja é obrigatório");
            if (!this.new_store_account.store_city) return emitToastr("error", "Cidade da loja é obrigatório");

            if (!this.new_store_account.user_email) return emitToastr("error", "Email da loja é obrigatório");
            if (!this.new_store_account.user_name) return emitToastr("error", "Nome e sobrenome são obrigatórios");
            if (!this.new_store_account.user_password) return emitToastr("error", "Senha de acesso é obrigatória");
            if (this.new_store_account.user_password.length < 6) return emitToastr("error", "A senha de acesso precisa ter pelo menos 6 dígitos");
            if (this.new_store_account.user_password != this.new_store_account.password2) return emitToastr("error", "As senhas não coincidem");

            const resp = await Api.createNewStoreRegister(this.new_store_account);

            if (resp && !resp.error) {
                emitToastr("success", "Cadastro efetuado com sucesso. Por favor aguarde o retorno da nossa equipe de análise.");
                this.new_store_account = {};
                this.success = true;
            }
        }
    },
};
</script>
  
<style scoped>
.login_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    align-content: center;
    align-items: center;
    background: rgb(0, 0, 0);
    background: radial-gradient(#1a66DB, #123CD7);
}

.logo {
    max-width: 400px;
    max-height: 330px;
}

.register-card {
    width: 90vw;
    max-width: 500px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
    .login_container {
        text-align: center;
        padding: 8%;
        padding-top: 2%;
    }

    .logo {
        max-width: 90vw;
        max-height: 180px;
    }

}
</style>
  