<template>
    <div class="menu-page">
        <h1>Lojas</h1>

        <div class="find-bar mb-4">
            <v-text-field autocomplete="nope" dense v-model="filter" label="Filtro por nome, estado, cidade ou telefone" outlined hide-details="auto"></v-text-field>
        </div>

        <div class="new-store-button mb-4">
            <v-btn class="default-button" color="#123cd7" @click.native="new_store = {}; modalNewStore = true">Nova loja</v-btn>
        </div>

        <div>
            <v-data-table no-data-text="Sem lojas" :headers="headers" :items="stores" :search="filter" :items-per-page="50" class="elevation-1" :footer-props="{
                'items-per-page-text': 'Linhas por página',
                'items-per-page-all-text': 'Tudo',
                'items-per-page-options': [5, 10, 30, 50, 100, -1],
            }">

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions-container">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium class="mr-4" color="primary" @click.stop="new_store = item; modalNewStore = true" v-bind="attrs" v-on="on"> mdi-store-edit-outline</v-icon>
                            </template>
                            <span>Editar loja</span>
                        </v-tooltip>

                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium class="mr-4" color="error" @click.stop="new_store = item; dialog = true" v-bind="attrs" v-on="on"> mdi-store-off-outline</v-icon>
                            </template>
                            <span>Apagar loja</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </div>

        <v-bottom-sheet v-model="modalNewStore">
            <v-sheet class="bottom-modal-container" height="auto">
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_store.name" label="Nome da loja" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" maxlength="60" dense v-model="new_store.description" label="Descrição" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="$root.$refs.global.states" v-model="new_store.state" item-text="name" item-value="id" label="Estado" outlined hide-details="auto"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-model="new_store.city" label="Cidade" outlined hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-mask="'(##) #########'" v-model="new_store.phone" label="Telefone" outlined hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="6">
                        <v-text-field autocomplete="nope" dense v-mask="'##.###.###/####-##'" label="CNPJ" v-model="new_store.cnpj" outlined hide-details="auto"></v-text-field>
                    </v-col>


                    <v-col cols="12">
                        <v-btn class="default-button" color="#123cd7" block @click.native="createOrUpdateStore" :loading="buttonLoading">Salvar Loja</v-btn>
                    </v-col>

                </v-row>
            </v-sheet>
        </v-bottom-sheet>

        <v-dialog v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar loja
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a loja <b>{{ new_store.name }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; deleteStore()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "StoresView",

    data() {
        return {
            stores: [],
            modalNewStore: false,
            new_store: {},
            filter: "",
            noDataText: "Nenhuma opção",
            buttonLoading: false,
            dialog: false,
            headers: [
                { text: "Nome", value: "name", filterable: true },
                { text: "Descrição", value: "description", filterable: true },
                { text: "Estado", value: "state", filterable: true },
                { text: "Cidade", value: "city", filterable: true },
                { text: "Telefone", value: "phone", filterable: true },
                { text: "E-mail Lojista", value: "user_email", filterable: true },
                { text: "Ações", value: "actions" },
            ]
        };
    },


    async mounted() {
        await this.getStores();
    },

    methods: {

        async getStores() {
            const resp = await Api.getStores();
            if (resp && resp.message) this.stores = resp.message;
        },

        async createOrUpdateStore() {
            if (!this.new_store.name) return emitToastr('error', 'Nome obrigatório.');
            if (!this.new_store.state) return emitToastr('error', 'Estado obrigatório.');
            if (!this.new_store.city) return emitToastr('error', 'Cidade obrigatória.');
            if (!this.new_store.phone) return emitToastr('error', 'Telefone obrigatório.');


            this.buttonLoading = true;

            const resp = await Api.createOrUpdateStore(this.new_store);
            if (resp && resp.message) {
                this.limpaFormulario();
                this.modalNewStore = false;
                this.buttonLoading = false;
                this.getStores();
                return emitToastr('success', 'Loja cadastrada com sucesso.');
            }
        },

        limpaFormulario() {
            this.new_store = {};
        },

        async deleteStore() {
            this.new_store.filed = true;

            const resp = await Api.createOrUpdateStore(this.new_store);
            if (resp && !resp.error) {
                this.limpaFormulario();
                this.modalNewStore = false;
                this.buttonLoading = false;
                this.getStores();
                return emitToastr('success', 'Loja apagada com sucesso.');
            }
        }
    },
};
</script>
  
<style scoped>
.find-bar {
    display: flex;
    flex-direction: row;
    max-width: 400px;

}

.new-store-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.actions-container {
    display: flex;
    flex-direction: row;

}

.bottom-modal-container {
    padding: 4%;
}

@media only screen and (max-width: 768px) {}
</style>
  