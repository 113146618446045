<template>
    <div class="menu-page">
        <h1>Carros</h1>

        <v-expansion-panels v-model="openPanel">
            <v-expansion-panel>
                <v-expansion-panel-header>Filtros {{ Object.keys(filter).length > 0 ? '(exibindo listagem com filtro)' : '' }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card flat>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="default-button" color="red lighten-2" @click.native="clearFilter">Limpar Filtros</v-btn>
                            <v-btn class="default-button" color="#123cd7" @click.stop="getCars">Filtrar</v-btn>
                        </v-card-actions>
                        <v-card-text>
                            <v-row>

                                <!--
                                <v-col cols="12" lg="4" md="4" sm="6">
                                    <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="$root.$refs.global.states" v-model="filter.state" item-text="name" item-value="id" label="Estado" outlined hide-details="auto"></v-autocomplete>
                                </v-col>
                                -->

                                <v-col cols="12" lg="4" md="4" sm="6">
                                    <v-text-field autocomplete="nope" dense clearable v-model="filter.brand" label="Marca" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="4" md="4" sm="6">
                                    <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="$root.$refs.global.gearboxes" v-model="filter.gearbox" item-text="name" item-value="id" label="Câmbio" outlined hide-details="auto"></v-autocomplete>
                                </v-col>

                                <v-col cols="12" lg="4" md="4" sm="6">
                                    <v-autocomplete autocomplete="nope" :no-data-text="noDataText" dense :items="$root.$refs.global.fuels" v-model="filter.fuel" item-text="name" item-value="id" label="Combustível" outlined hide-details="auto"></v-autocomplete>
                                </v-col>

                                <v-col cols="12" lg="4" md="4" sm="6">
                                    <v-text-field type="number" autocomplete="nope" dense v-model="filter.min_price" label="Preço mínimo" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="4" md="4" sm="6">
                                    <v-text-field type="number" autocomplete="nope" dense v-model="filter.max_price" label="Preço máximo" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="4" md="4" sm="6">
                                    <v-text-field type="number" autocomplete="nope" dense v-model="filter.min_year" label="Ano mínimo" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="4" md="4" sm="6">
                                    <v-text-field type="number" autocomplete="nope" dense v-model="filter.max_year" label="Ano máximo" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="4" md="4" sm="6">

                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <div class="mt-8">
            <v-row v-if="!loading">
                <v-col cols="12">
                    <v-alert type="success" icon="mdi-car-search">
                        Ofertas atualizadas diariamente em todo o Brasil
                    </v-alert>
                </v-col>

                <v-col cols="12" class="mt-4" v-if="cars.length === 0">
                    <h3>Sem carros cadastrados.</h3>
                </v-col>

                <v-col v-for="(item, index) in cars" :key="index" cols="12" md="4" sm="6">
                    <v-card class="card noselect" @click.native="openCar(item)">
                        <v-chip v-if="item.sold" class="sold-chip ma-2" color="green" text-color="white">
                            <v-avatar left>
                                <v-icon>mdi-check</v-icon>
                            </v-avatar>
                            Vendido!
                        </v-chip>

                        <v-img class="car-image" v-if="item.pictures" :aspect-ratio="4 / 3" :src="item.pictures[item.picture_index || 0].image">
                            <div class="change-image-container">
                                <v-btn class="change-image-icon" fab icon small outlined dark @click.stop="changeImage(item, -1)"><v-icon large>mdi-chevron-left</v-icon></v-btn>
                                <v-btn class="change-image-icon" fab icon small outlined dark @click.stop="changeImage(item, 1)"><v-icon large>mdi-chevron-right</v-icon></v-btn>
                            </div>
                        </v-img>
                        <v-skeleton-loader v-if="!item.pictures" class="mx-auto" type="image"></v-skeleton-loader>

                        <v-card-title class="two-lines-text card-title" style="height: 64px">{{ item.model }} </v-card-title>

                        <v-card-subtitle class="card-subtitle" style="margin-bottom: 0px">
                            {{ item.brand }}

                            <div class="price">
                                R$ {{ item.price.toLocaleString('pt') }}
                            </div>
                        </v-card-subtitle>

                        <div class="mt-4">
                            <v-alert type="success" class="pa-2 pl-4" prominent tile icon="mdi-currency-usd" >
                                
                                <div class="pr-4" style="display: flex; flex-direction: column; align-items: flex-end;">
                                    <v-spacer></v-spacer>
                                    Abaixo da FIPE <h2>R$ {{ (item.fipe - item.price).toLocaleString('pt') }}</h2>
                                </div>
                            </v-alert>
                        </div>

                        <v-card-subtitle class="card-subtitle details mt-4 mb-1">
                            <div class="detail">
                                <div>Ano modelo</div>
                                <div>{{ item.model_year }}</div>
                            </div>
                            <div class="detail">
                                <div>Km</div>
                                <div v-if="item.km || item.km === 0">{{ item.km.toLocaleString('pt') }}</div>
                            </div>

                            <div class="detail">
                                <div>Câmbio</div>
                                <div>{{ item.gearbox }}</div>
                            </div>
                        </v-card-subtitle>

                        <v-card-subtitle class="location">
                            <div>Cidade </div>
                            <div>{{ item.store.city }} / {{ item.store.state }}</div>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>

            <div v-if="buttonMoreCarsVisible && !loading" class="text-center mt-8">
                <v-btn class="more-cars-button" rounded color="#123cd7" @click.native="moreCars" :disabled="button_loading">
                    {{ button_loading ? 'Carregando...' : 'Ver mais' }}
                </v-btn>
            </div>

            <div v-if="loading" class="text-center mt-8">
                <h3>Carregando ofertas...</h3>
            </div>
        </div>


        <v-dialog v-model="$route.query.exibe" id="details" fullscreen hide-overlay persistent transition="dialog-bottom-transition" class="details-container">
            <v-card v-if="selectedCar._id">
                <v-toolbar dark class="bar-car-details">
                    <v-toolbar-title>{{ selectedCar.model }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="$router.back()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <div class="sold-chip-details-container">
                    <v-chip large v-if="selectedCar.sold" color="green" text-color="white">
                        <v-avatar left>
                            <v-icon>mdi-check</v-icon>
                        </v-avatar>
                        Vendido!
                    </v-chip>
                </div>

                <v-carousel v-model="imageIndex" cycle class="carousel">
                    <v-carousel-item v-for="(pic, index) in selectedCar.pictures" :key="index" :src="pic.image" contain>
                    </v-carousel-item>
                </v-carousel>

                <div class="car-details-container">
                    <v-card flat class="car-details-card">
                        <v-card-title class="card-title-details">{{ selectedCar.model }} </v-card-title>
                        <v-card-subtitle>{{ selectedCar.brand }}</v-card-subtitle>

                        <v-card-subtitle class="card-subtitle" style="margin-top:4px; margin-bottom: 14px">
                            <div class="line-container">
                                <div class="price column-container">
                                    <span class="subtitle-1">Preço de venda</span>
                                    <span class="mt-2">R$ {{ selectedCar.price.toLocaleString('pt') }}</span>
                                </div>

                                <div v-if="selectedCar.fipe" class="price column-container">
                                    <span class="subtitle-1">Tabela FIPE</span>
                                    <span class="mt-2">R$ {{ selectedCar.fipe.toLocaleString('pt') }}</span>
                                </div>

                                <v-chip v-if="selectedCar.fipe" class="ma-2" color="green" text-color="white">
                                    <v-avatar left>
                                        <v-icon>mdi-currency-usd</v-icon>
                                    </v-avatar>
                                    Margem de lucro R$ {{ (selectedCar.fipe - selectedCar.price).toLocaleString('pt') }}
                                </v-chip>
                            </div>


                        </v-card-subtitle>

                        <v-divider class="mt-2"></v-divider>

                        <v-card-subtitle class="card-subtitle details mt-4 mb-1">
                            <div class="detail">
                                <div class="subtitle-2">Ano modelo</div>
                                <div>{{ selectedCar.model_year }}</div>
                            </div>
                            <div class="detail">
                                <div class="subtitle-2">Km</div>
                                <div v-if="selectedCar.km || selectedCar.km === 0">{{ selectedCar.km.toLocaleString('pt') }}</div>
                            </div>

                            <div class="detail">
                                <div class="subtitle-2">Câmbio</div>
                                <div>{{ selectedCar.gearbox }}</div>
                            </div>
                        </v-card-subtitle>

                        <v-card-subtitle class="card-subtitle details mt-4 mb-1">
                            <div class="detail">
                                <div class="subtitle-2">Combustível</div>
                                <div>{{ selectedCar.fuel }}</div>
                            </div>
                        </v-card-subtitle>

                        <v-divider class="mt-2"></v-divider>

                        <v-card-subtitle v-if="selectedCar.description" class="card-subtitle details">
                            {{ selectedCar.description }}
                        </v-card-subtitle>

                        <v-divider v-if="selectedCar.description" class="mt-2"></v-divider>

                        <v-card-subtitle v-if="!selectedCar.sold" class="seller">
                            <div>
                                <div class="font-weight-bold">Cidade</div>
                                <div>{{ selectedCar.store.city }} / {{ selectedCar.store.state }}</div>
                            </div>
                            <v-btn color="#123cd7" class="ml-12 white--text" @click.native="sellerDialog = true">
                                <v-icon left>
                                    mdi-eye
                                </v-icon>
                                Contatar vendedor
                            </v-btn>

                            <v-spacer></v-spacer>
                        </v-card-subtitle>

                        <v-card-subtitle v-if="selectedCar.sold" class="seller" style="justify-content: center;">
                            <v-chip color="green" text-color="white">
                                <v-avatar left>
                                    <v-icon>mdi-check</v-icon>
                                </v-avatar>
                                Vendido!
                            </v-chip>
                        </v-card-subtitle>

                        <v-divider class="mt-2"></v-divider>

                        <v-card-subtitle style="text-align: center;">
                            <v-spacer></v-spacer>
                            <v-btn small color="error" class="white--text" @click.native="denounceDialog = true">
                                <v-icon left>
                                    mdi-alert-decagram-outline
                                </v-icon>
                                Denunciar
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-subtitle>
                    </v-card>
                </div>

            </v-card>
        </v-dialog>

        <v-dialog v-model="sellerDialog" max-width="450">
            <v-card v-if="selectedCar && selectedCar.store">
                <v-card-title class="text-h5">
                    Dados do vendedor
                </v-card-title>
                <v-card-text class="store-data"><b>{{ selectedCar.store.name }}</b></v-card-text>
                <v-card-text class="store-data"><b>Localidade:</b> {{ selectedCar.store.city }} / {{ selectedCar.store.state }}</v-card-text>
                <v-card-text class="store-data mb-4" v-if="selectedCar.store.phone"><b>Telefone:</b> {{ selectedCar.store.phone }}</v-card-text>
                <v-card-text class="text-14">

                    <i><b>A responsabilidade da negociação, pagamentos e transporte é diretamente entre você e a concessionária, bem como a confirmação de dados e documentação do veículo.</b>
                        Sugerimos que antes de fechar o negócio e fazer o pagamento, consulte todos os débitos e também faça uma consulta privada de leilão/sinistro, mesmo que informado pela loja a inexistência, a fim de evitar qualquer possível erro de comunicação. 🚨</i>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#123cd7" text @click="sellerDialog = false;">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="denounceDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Denunciar anúncio
                </v-card-title>
                <v-card-text>Por favor, compartilhe detalhes sobre o conteúdo dessa denúncia. Nossa equipe analisará o anúncio, a loja e sua mensagem para tomar medidas o mais rápido possível.</v-card-text>
                <v-card-text>
                    <v-textarea v-model="denounceText" outlined rows="5"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="denounceDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click.native="newDenounce">
                        Denunciar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "CarsView",

    data() {
        return {
            cars: [],
            selectedPictures: {},
            filter: {},
            noDataText: "Nenhuma opção",
            denounceText: "",
            dialog: false,
            sellerDialog: false,
            denounceDialog: false,
            openPanel: false,
            imageIndex: 0,
            selectedCar: { model: '', brand: '', pictures: [], price: '', model_year: '', km: '', gearbox: '', fuel: '', fipe: '', gas_kit: '', sold: '', featured: false },
            skip: 0,
            buttonMoreCarsVisible: true,
            maxCarsPerCall: 9,
            loading: true,
            button_loading: false,
            delay: 4000
        };
    },

    async mounted() {
        await this.getCars();
        if (this.$route.query && this.$route.query.exibe && !this.selectedCar._id) this.$router.replace('/carros')
    },

    components: {},

    methods: {
        async clearFilter() {
            this.filter = {};
            await this.getCars();
            this.openPanel = false;
        },

        async getCars() {
            this.loading = true;


            setTimeout(async () => {
                const resp = await Api.getCars({ filter: this.filter, limit: this.maxCarsPerCall });
                if (resp && resp.message) {
                    this.cars = resp.message;
                    this.cars = this.cars.sort((a, b) => { return (a.featured > b.featured) ? -1 : ((b.featured > a.featured) ? 1 : 0) });
                    if (this.cars.length === 0) this.buttonMoreCarsVisible = false;
                    this.loading = false;
                }
                this.loading = false;
            }, this.delay);
        },

        async newDenounce() {
            const resp = await Api.createOrUpdateDenounce({ car: this.selectedCar, description: this.denounceText });
            if (resp && !resp.error) {
                emitToastr('success', 'Denúncia enviada com sucesso. Por favor aguarde a análise.');
            }

            this.denounceText = "";
            this.denounceDialog = false;
        },

        async moreCars() {
            this.skip += this.maxCarsPerCall;

            this.button_loading = true;

            setTimeout(async () => {
                const resp = await Api.getCars({ filter: this.filter, limit: this.maxCarsPerCall, skip: this.skip });
                if (resp && resp.message) {
                    if (resp.message.length < this.maxCarsPerCall) this.buttonMoreCarsVisible = false;
                    this.cars.push(...resp.message)
                    this.cars = this.cars.sort((a, b) => { return (a.featured > b.featured) ? -1 : ((b.featured > a.featured) ? 1 : 0) });
                    if (this.cars.length === 0) this.buttonMoreCarsVisible = false;
                }

                this.button_loading = false;


            }, this.delay);
        },

        changeImage(item, value) {
            if (value == 1) {
                if (item.picture_index < item.pictures.length - 1) item.picture_index++;
            }

            if (value == -1) {
                if (item.picture_index > 0) item.picture_index--;
            }
        },

        openCar(car) {
            this.selectedCar = car;
            this.imageIndex = 0;
            if (!this.$route.query.exibe) this.$router.push({ path: `/carros`, query: { exibe: true } });
            setTimeout(() => {
                const div = document.getElementsByClassName('v-dialog--fullscreen');
                div[0].scrollTop = 0
            }, 50);
        }
    },
};
</script>
  
<style scoped>
.card {
    cursor: pointer;
    transition: 0.3s;
}

.card:hover {
    transform: translateY(-8px);
}

.change-image-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.change-image-icon {
    opacity: 0.3;
    margin-left: 3px;
    margin-right: 3px;
}

.change-image-icon:hover {
    opacity: 1;
}


.card-title {
    color: #123cd7;
    font-weight: bold;
}

.price {
    font-size: 32px;
    font-weight: bold;
    color: black;
}

.profit-container {
    display: flex;
    justify-content: flex-end;
}

.sold-chip {
    position: absolute;
    z-index: 5;
    transform: translate(-15px, -22px);
}

.card-subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.details-container {
    width: 100vw;
    overflow-x: hidden;
}

.details {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.detail {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: black !important;
}

.location {
    color: black !important;
    font-weight: 400;
    font-size: 14px;
}

.seller {
    display: flex;
    flex-direction: row;
    color: black !important;
    font-weight: 400;
    font-size: 14px;
}

.more-cars-button {
    color: white;
}

.bar-car-details {
    background: radial-gradient(#1a66DB, #123CD7);
    border-radius: 0px !important;
}

.carousel {
    background: radial-gradient(#1a66DB, #123CD7);
}

.car-details-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.car-details-card {
    width: 100vw;
    max-width: 900px !important;
}


.card-title-details {
    color: #1d388b;
    font-size: 24px;
    font-weight: bold;
    word-break: break-word;
}

.column-container {
    display: flex;
    flex-direction: column;
}

.line-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.sold-chip-details-container {
    position: absolute;
    z-index: 99;
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.store-data {
    font-size: 16px;
    padding-bottom: 10px !important;
}

.text-14 {
    font-size: 14px !important;
}

@media only screen and (max-width: 768px) {
    .line-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>
  